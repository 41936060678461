import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
    <footer>
      <div class="footer-text">
        © 2020 deshmukhi.in
      </div>
    </footer>
  `,
  styles: [`
    .footer-text {
      width: 100%;
      margin: 2em auto;
      text-align: center;
      color: #8E24AAAA;
      user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-user-select: none;
    }
  `]
})
export class FooterComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
