import {Component, OnInit} from '@angular/core';
import {DataService} from '../../service/data.service';
import {Language} from '../../data/language';
import {Strings} from "../../data/strings";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  strings: Strings;

  constructor(private dataService: DataService) {
  }

  ngOnInit(): void {
    this.dataService.getStrings().subscribe(data => this.strings = data);
  }

  onEnglishSelected() {
    this.dataService.setLanguage(Language.ENGLISH);
  }

  onMarathiSelected() {
    this.dataService.setLanguage(Language.MARATHI);
  }

}
