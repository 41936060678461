import {Component, OnInit, ViewRef} from '@angular/core';
import {SwiperOptions} from "swiper";

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})
export class TestimonialComponent implements OnInit {

  config: SwiperOptions = {
    init: true,
    roundLengths: true,
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true
    },

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    // spaceBetween: 28
  };

  constructor() {
  }

  ngOnInit(): void {

  }

}
