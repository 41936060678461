import {Injectable} from '@angular/core';
import {Strings} from '../data/strings';
import {EnglishStrings} from '../data/strings.en';
import {BehaviorSubject, Observable} from 'rxjs';
import {Language} from '../data/language';
import {MarathiStrings} from '../data/strings.mr';
import {Country, countryList} from "../data/countries";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  stringData: BehaviorSubject<Strings>;
  countryListData: BehaviorSubject<Country[]>;
  private selectedLanguage: Language;
  private englishStringsData = new EnglishStrings();
  private marathiStringsData = new MarathiStrings();
  private englishCountries = countryList.map(info => new Country(`+${info.code.padEnd(5, '\u2007')}  ${info.enName}`, info.code));
  private marathiCountries = countryList.map(info => new Country(`+${info.code.padEnd(5, '\u2007')}  ${info.mrName}`, info.code));

  constructor() {
    this.selectedLanguage = Language.ENGLISH;
    this.stringData = new BehaviorSubject<Strings>(this.englishStringsData);
    this.countryListData = new BehaviorSubject<Country[]>(this.englishCountries);
  }

  getStrings(): Observable<Strings> {
    return this.stringData;
  }

  getCountryList(): Observable<Country[]> {
    return this.countryListData;
  }

  setLanguage(language: Language): void {
    this.selectedLanguage = language
    switch (language) {
      case Language.ENGLISH:
        this.stringData.next(this.englishStringsData);
        this.countryListData.next(this.englishCountries);
        break;
      case Language.MARATHI:
        this.stringData.next(this.marathiStringsData);
        this.countryListData.next(this.marathiCountries);
        break;
    }
  }

  getLanguageCode(): string {
    if (this.selectedLanguage == Language.ENGLISH)
      return "en"
    else
      return "mr"
  }
}
