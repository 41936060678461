import {Strings} from './strings';

export class EnglishStrings implements Strings {
  language = "Language";
  languageEn = "English";
  languageMr = "Marathi";
  descriptionText1 = `Deshmukhi is a social experiment, the purpose of which is to give a new energy to the Maratha Deshmukh understanding.
     Since the few ages, for a variety of reasons, the gap between the group, which was considered to be the single most advanced, has been widening.
     People from all walks of life are feeling the effects of this distance.
     For example`;
  descriptionText2List = [
    'Growing economic / social inequality in the society',
    'Difficulties in marriage management',
    'A change in the way we look at society',
    'The struggle of change in the new, old generation',
    'The effect of all these on the family system.'
  ];
  descriptionText3 = 'Considering the above and many more points, it is a sincere attempt to bring the Deshmukh community together once again in a compassionate exchange of thoughts.';
  descriptionText4 = 'Distinguished brothers and sisters of all ages in the society are requested to cooperate in social work by filling in their information in the following form. Also, if you have any suggestions, expectations or concerns about the community/experiment, please let us know in the comments section.';
  formLabelComment = 'Comments';
  formLabelCommentType = 'Comment Type';
  formLabelCommentTypeExpectations = 'Expectations';
  formLabelCommentTypeInstructions = 'Instructions';
  formLabelCommentTypeWorries = 'Concerns';
  formLabelEmail = 'Email';
  formLabelFirstName = 'First Name';
  formLabelGender = 'Gender';
  formLabelGenderFemale = 'Female';
  formLabelGenderMale = 'Male';
  formLabelMaritalStatus = 'Marital Status';
  formLabelMaritalStatusDivorced = 'Divorced';
  formLabelMaritalStatusMarried = 'Married';
  formLabelMaritalStatusSingle = 'Single';
  formLabelMaritalStatusWidowed = 'Widowed';
  formLabelAgeGroup = 'Age Group';
  formLabelAgeGroup_18_24 = '18-24';
  formLabelAgeGroup_25_34 = '25-34';
  formLabelAgeGroup_35_44 = '35-44';
  formLabelAgeGroup_45_54 = '45-54';
  formLabelAgeGroup_55_64 = '55-64';
  formLabelAgeGroup_65_MORE: '65 or more';
  formLabelLastName = 'Last Name';
  formLabelMiddleName = 'Father\'s Name';
  formLabelNativePlace = 'Native Place';
  formLabelOriginalSurname = 'Original Surname';
  formLabelPhoneNumber = 'Phone Number';
  formLabelSubmit = 'Submit';
  formTitle = 'I am Deshmukh';
  subtitle = 'By Deshmukh, For Deshmukh';
  errorOriginalSurnameRequired = 'Original name is required';
  errorNativePlaceRequired = 'Please enter native place';
  errorFirstNameRequired = 'First name is must';
  errorMiddleNameRequired = 'Middle name is must';
  errorLastNameRequired = 'Last name is must';
}
