import {Strings} from './strings';

export class MarathiStrings implements Strings {
  language = "भाषा";
  languageEn = "इंग्रजी";
  languageMr = "मराठी";
  descriptionText1 = `देशमुखी हा एक समाजिक प्रयोग आहे, ज्याचे उद्देश्य फक्त आणी फक्त मराठा देशमुख समजाला एक नविन ऊर्जा देणे हा आहे.
     मधल्या काळापासुन वेगवेगळ्या कारणांमुळे, एकेकळी प्रगल्भ आणी प्रगत समजल्या जाणाऱ्या समूहामध्ये दुरावा वाढतच गेला आहे.
     ह्या दुराव्याची झळ समाजातील प्रत्येक स्तरावरील लोकांना जाणवत आहे.
     उदा.`;
  descriptionText2List = [
    'समाजातील वाढती आर्थिक / समाजिक विषमता',
    'लग्नसंबंध व्यवस्थापनामध्ये येणाऱ्या अडचणी',
    'समाजाकडे बघण्याच्या दृष्टिकोणात होणारा बदल',
    'बदलांचा नव्या, जुन्या पिढीत होणारा संघर्ष',
    'ह्या सगळ्यांचा कुटुंब पध्दतीवर होणारा परिणाम.'
  ];
  descriptionText3 = 'वरिल आणि अजुन बरेच काही मुद्दे लक्षात घेता, पुन्हा एकदा देशमुख समजाला एकत्र करुण विचार विनीमय घडवुन आणण्याचा हा एक प्रामाणिक प्रयत्न आहे.';
  descriptionText4 = 'समाजातील सर्व वयोंगटातील मान्यवर बंधु आणी भगिनींना विनंती आहे की, खालील फॉर्म मधे आपली महिती भरुन समाजिक कार्याला सहकार्य करावे. तसेच समाजाबद्दल/प्रयोगाबद्दल आपल्या मनांत काही सूचना, अपेक्षा किंवा चिंता असतील तर दिलेल्या टिपण्णी विभागामधे लिहुन कळवावे.';
  formLabelComment = 'टिप्पणी...';
  formLabelCommentType = '';
  formLabelCommentTypeExpectations = 'अपेक्षा';
  formLabelCommentTypeInstructions = 'सूचना';
  formLabelCommentTypeWorries = 'चिंता';
  formLabelEmail = 'ई-मेल';
  formLabelFirstName = 'नाव';
  formLabelGender = 'लिंग';
  formLabelGenderFemale = 'स्त्री';
  formLabelGenderMale = 'पुरुष';
  formLabelMaritalStatus = 'वैवाहिक स्थिती';
  formLabelMaritalStatusDivorced = 'घटस्फोटित';
  formLabelMaritalStatusMarried = 'विवाहित';
  formLabelMaritalStatusSingle = 'अविवाहित';
  formLabelMaritalStatusWidowed = 'विधुर|विधवा';
  formLabelAgeGroup = 'वयोगट';
  formLabelAgeGroup_18_24 = '१८-२४';
  formLabelAgeGroup_25_34 = '२५-३४';
  formLabelAgeGroup_35_44 = '३५-४४';
  formLabelAgeGroup_45_54 = '४५-५४';
  formLabelAgeGroup_55_64 = '५५-६४';
  formLabelAgeGroup_65_MORE = '६५ किंवा जास्त';
  formLabelLastName = 'आडनाव';
  formLabelMiddleName = 'वडिलांचे नाव';
  formLabelNativePlace = 'मूळ गाव';
  formLabelOriginalSurname = 'मूळ आडनाव';
  formLabelPhoneNumber = 'फोन नंबर';
  formLabelSubmit = 'पूर्ण करा';
  formTitle = 'मी देशमुख!';
  subtitle = 'देशमुखांचि देशमुखांसाठी !!';
  errorOriginalSurnameRequired = '';
  errorNativePlaceRequired = '';
  errorFirstNameRequired = '';
  errorMiddleNameRequired = '';
  errorLastNameRequired = '';
}
