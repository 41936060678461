import {Component, OnInit} from '@angular/core';
import {DataService} from '../../service/data.service';
import {Strings} from '../../data/strings';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {PhoneValidator} from "./validators/phone.validator";
import {Country} from "../../data/countries";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {OriginalSurnameValidator} from "./validators/OriginalSurname.validator";
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  strings: Strings;
  countryList: Country[];

  formGroup;
  countryControl = new FormControl('', []);

  selectedNativePlace: any;

  constructor(
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private http: HttpClient
  ) {
    this.formGroup = this.formBuilder.group({
      originalSurname: new FormControl('', [
          Validators.required,
          OriginalSurnameValidator.validOriginalSurname()
        ]
      ),
      nativePlace: new FormControl('', [
          Validators.required
        ]
      ),
      firstName: new FormControl('', [
          Validators.required
        ]
      ),
      lastName: new FormControl('', [
          Validators.required
        ]
      ),
      middleName: new FormControl('', [
          Validators.required
        ]
      ),
      gender: new FormControl('', []),
      maritalStatus: new FormControl('', []),
      ageGroup: new FormControl('', []),
      country: this.countryControl,
      phoneNumber: new FormControl('', [
          PhoneValidator.validCountryPhone(this.countryControl)
        ]
      ),
      email: new FormControl('', [
          Validators.email
        ]
      ),
      commentType: '',
      comment: ''
    });
  }

  ngOnInit(): void {
    this.dataService.getStrings().subscribe(data => this.strings = data);
    this.dataService.getCountryList().subscribe(data => this.countryList = data);
  }

  onAutocompleteSelected(result: PlaceResult) {
    this.selectedNativePlace = {
      address: result.formatted_address,
      location: result.geometry.location.toJSON(),
      details: result.address_components
    }
  }

  onSubmit(formData): void {
    // Change the native place location data
    formData["nativePlace"] = this.selectedNativePlace

    // Add language
    formData["language"] = this.dataService.getLanguageCode()

    this.http.post(environment.formEndpoint, formData).subscribe((response) => {
      console.log(JSON.stringify(response, null, 3));
    })

    // Process form here
    this.formGroup.reset();

    console.warn('Your form has been submitted');
  }
}
