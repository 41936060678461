import {Component, OnInit} from '@angular/core';
import {Strings} from '../../data/strings';
import {DataService} from '../../service/data.service';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.css']
})
export class DescriptionComponent implements OnInit {

  strings: Strings;

  constructor(private dataService: DataService) {
  }

  ngOnInit(): void {
    this.dataService.getStrings().subscribe(data => this.strings = data);
  }
}
