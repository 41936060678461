import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <app-header></app-header>
    <main>
      <div fxLayout="column" fxLayoutGap="2em" style="padding: 0">
        <app-description class="container"></app-description>
        <app-form class="container"></app-form>
      </div>
    </main>
    <app-footer class="container"></app-footer>
  `
})
export class AppComponent {
}

// TODO:
// Performance
// Authenticity: Less Colors, fonts should justify professional
// Make something good with D and I
// Make column one page scrollable layout
