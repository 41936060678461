<div fxLayout="column" fxFill>
  <div>
    <p class="mat-title">{{strings.formTitle}}</p>
  </div>
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="">
    <!-- Native Block -->
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em" fxLayoutGap.lt-md="0">
      <!-- Original Surname -->
      <mat-form-field fxFlex="50">
        <mat-label>{{strings.formLabelOriginalSurname}}</mat-label>
        <label for="original_surname"></label>
        <input
          id="original_surname"
          formControlName="originalSurname"
          aria-required="true"
          type="text"
          required matInput>
        <mat-error *ngIf="formGroup.controls['originalSurname'].hasError('required')">
          {{strings.errorOriginalSurnameRequired}}
        </mat-error>
        <mat-error *ngIf="formGroup.controls['originalSurname'].hasError('isValidSurname')">
          Original Surname cannot be "Deshmukh"
        </mat-error>
      </mat-form-field>
      <!-- Native Place -->
      <mat-form-field fxFlex="50">
        <mat-label>{{strings.formLabelNativePlace}}</mat-label>
        <label for="native_place"></label>
        <input
          id="native_place"
          formControlName="nativePlace"
          aria-required="true"
          type="text"
          country="IN"
          (onAutocompleteSelected)="onAutocompleteSelected($event)"
          required matInput matGoogleMapsAutocomplete>
        <mat-error *ngIf="formGroup.controls['nativePlace'].hasError('required')">
          {{strings.errorNativePlaceRequired}}
        </mat-error>
      </mat-form-field>
    </div>
    <!-- Name Block -->
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em" fxLayoutGap.lt-md="0">
      <!-- First Name -->
      <mat-form-field fxFlex="33">
        <mat-label>{{strings.formLabelFirstName}}</mat-label>
        <label for="first_name"></label>
        <input
          id="first_name"
          formControlName="firstName"
          aria-required="true"
          type="text"
          matInput required>
        <mat-error *ngIf="formGroup.controls['firstName'].hasError('required')">
          {{strings.errorFirstNameRequired}}
        </mat-error>
      </mat-form-field>
      <!-- Middle Name -->
      <mat-form-field fxFlex="33">
        <mat-label>{{strings.formLabelMiddleName}}</mat-label>
        <label for="middle_name"></label>
        <input
          id="middle_name"
          formControlName="middleName"
          aria-required="true"
          type="text"
          required matInput>
        <mat-error *ngIf="formGroup.controls['middleName'].hasError('required')">
          {{strings.errorMiddleNameRequired}}
        </mat-error>
      </mat-form-field>
      <!-- Last Name -->
      <mat-form-field fxFlex="33">
        <mat-label>{{strings.formLabelLastName}}</mat-label>
        <label for="last_name"></label>
        <input
          formControlName="lastName"
          aria-required="true"
          id="last_name"
          type="text"
          required matInput>
        <mat-error *ngIf="formGroup.controls['lastName'].hasError('required')">
          {{strings.errorLastNameRequired}}
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Gender -->
    <div fxLayout="row">
      <mat-form-field fxFlex="30" style="margin-right: 1em">
        <mat-label>{{strings.formLabelGender}}</mat-label>
        <mat-select formControlName="gender">
          <mat-option value="female">{{strings.formLabelGenderFemale}}</mat-option>
          <mat-option value="male">{{strings.formLabelGenderMale}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="70">
        <mat-label>{{strings.formLabelMaritalStatus}}</mat-label>
        <mat-select formControlName="maritalStatus">
          <mat-option value="single">{{strings.formLabelMaritalStatusSingle}}</mat-option>
          <mat-option value="married">{{strings.formLabelMaritalStatusMarried}}</mat-option>
          <mat-option value="divorced">{{strings.formLabelMaritalStatusDivorced}}</mat-option>
          <mat-option value="widowed">{{strings.formLabelMaritalStatusWidowed}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Phone -->
    <div fxLayout="row">
      <mat-form-field fxFlex="30" style="margin-right: 1em">
        <mat-label>Country</mat-label>
        <label for="country"></label>
        <mat-select
          id="country"
          formControlName="country"
          aria-required="false">
          <mat-option *ngFor="let country of countryList" [value]="country.code">{{country.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="70">
        <mat-label>{{strings.formLabelPhoneNumber}}</mat-label>
        <label for="phone"></label>
        <input
          id="phone"
          formControlName="phoneNumber"
          aria-required="false"
          type="tel"
          matInput>
      </mat-form-field>
    </div>

    <!-- Phone Email -->
    <div fxLayout="row">
      <mat-form-field fxFill>
        <mat-label>{{strings.formLabelEmail}}</mat-label>
        <label for="email"></label>
        <input
          id="email"
          formControlName="email"
          aria-required="false"
          type="email"
          matInput>
        <mat-error
          *ngIf="formGroup.controls['email'].hasError('email') && !formGroup.controls['email'].hasError('required')">

        </mat-error>
      </mat-form-field>
    </div>

    <!-- Comment | Max 500 characters -->
    <div fxLayout="row" fxLayout.lt-md="column">
      <mat-form-field fxFill>
        <mat-label>{{strings.formLabelComment}}</mat-label>
        <label for="comment"></label>
        <textarea
          id="comment"
          formControlName="comment"
          aria-multiline="true"
          aria-required="false"
          maxlength="500"
          matInput>
        </textarea>
        <mat-hint align="end">2 / 500</mat-hint>
      </mat-form-field>
    </div>
    <!-- Submit Button -->
    <div fxLayout="column">
      <div class="button-container g-recaptcha" data-sitekey="6Lc_mb8ZAAAAAPRutFb3oIAGHKiOSLIjeJPu1uh_"></div>
      <div class="button-container">
        <button mat-raised-button color="primary" class="submit-button" id="submit"
                [disabled]="!formGroup.valid">{{strings.formLabelSubmit}}</button>
      </div>
    </div>
  </form>
</div>
