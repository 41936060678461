<div>
  <p class="mat-title">
    {{strings.subtitle }}
  </p>
  <p>{{strings.descriptionText1 }}</p>
  <ul>
    <li *ngFor="let text of strings.descriptionText2List;">{{text}}</li>
  </ul>
  <p>{{strings.descriptionText3 }}</p>
  <p>{{strings.descriptionText4 }}</p>
</div>
