<div>
  <swiper [initialize]="true" class="swiper-container" [config]="config">
    <div class="swiper-wrapper">
      <app-testimony class="swiper-slide"></app-testimony>
      <app-testimony class="swiper-slide"></app-testimony>
      <app-testimony class="swiper-slide"></app-testimony>
    </div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
    <div class="swiper-pagination"></div>
    <!--<div fxLayout="row">
      <div fxFlex="10">
        <button mat-button class="swiper-button-prev">
          <mat-icon>navigate_before</mat-icon>
        </button>
      </div>
      <div fxFlex="10">
        <button mat-button class="swiper-button-next">
          <mat-icon>navigate_next</mat-icon>
        </button>
      </div>
    </div>-->
  </swiper>
</div>
