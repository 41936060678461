interface CountryInfo {
  enName: string;
  mrName: string;
  code: string;
}

class CountryInfoImpl {
  readonly enName: string;
  readonly mrName: string;
  readonly code: string;

  constructor(info: CountryInfo) {
    this.enName = info.enName;
    this.mrName = info.mrName;
    this.code = info.code;
  }
}

export class Country {
  readonly name: string;
  readonly code: string;

  constructor(name: string, code: string) {
    this.name = name;
    this.code = code;
  }
}

export const countryList = [
  new CountryInfoImpl({enName: "Afghanistan", mrName: "अफगाणिस्तान", code: "93"}),
  new CountryInfoImpl({enName: "Åland Islands", mrName: "अॅलँड बेटे", code: "358"}),
  new CountryInfoImpl({enName: "Albania", mrName: "अल्बेनिया", code: "355"}),
  new CountryInfoImpl({enName: "Algeria", mrName: "अल्जीरिया", code: "213"}),
  new CountryInfoImpl({enName: "American Samoa", mrName: "अमेरिकन सामोआ", code: "1684"}),
  new CountryInfoImpl({enName: "Andorra", mrName: "अँडोर", code: "376"}),
  new CountryInfoImpl({enName: "Angola", mrName: "अंगोला", code: "244"}),
  new CountryInfoImpl({enName: "Anguilla", mrName: "अँग्विला", code: "1264"}),
  new CountryInfoImpl({enName: "Antarctica", mrName: "अंटार्क्टिका", code: "672"}),
  new CountryInfoImpl({enName: "Antigua and Barbuda", mrName: "अँटिग्वा आणि बार्बुडा", code: "1268"}),
  new CountryInfoImpl({enName: "Argentina", mrName: "अर्जेंटिना", code: "54"}),
  new CountryInfoImpl({enName: "Armenia", mrName: "अर्मेनिया", code: "374"}),
  new CountryInfoImpl({enName: "Aruba", mrName: "अरुबा", code: "297"}),
  new CountryInfoImpl({enName: "Australia", mrName: "ऑस्ट्रेलिया", code: "61"}),
  new CountryInfoImpl({enName: "Austria", mrName: "ऑस्ट्रिया", code: "43"}),
  new CountryInfoImpl({enName: "Azerbaijan", mrName: "अझरबैजान", code: "994"}),
  new CountryInfoImpl({enName: "Bahamas", mrName: "बहामाज", code: "1242"}),
  new CountryInfoImpl({enName: "Bahrain", mrName: "बहरैन", code: "973"}),
  new CountryInfoImpl({enName: "Bangladesh", mrName: "बांगलादेश", code: "880"}),
  new CountryInfoImpl({enName: "Barbados", mrName: "बार्बाडोस", code: "1246"}),
  new CountryInfoImpl({enName: "Belarus", mrName: "बेलारूस", code: "375"}),
  new CountryInfoImpl({enName: "Belgium", mrName: "बेल्जियम", code: "32"}),
  new CountryInfoImpl({enName: "Belize", mrName: "बेलिझ", code: "501"}),
  new CountryInfoImpl({enName: "Benin", mrName: "बेनिन", code: "229"}),
  new CountryInfoImpl({enName: "Bermuda", mrName: "बर्म्युडा", code: "1441"}),
  new CountryInfoImpl({enName: "Bhutan", mrName: "भूतान", code: "975"}),
  new CountryInfoImpl({enName: "Plurinational State of Bolivia", mrName: "बोलिव्हिया प्लुरीनॅशनल राज्य", code: "591"}),
  new CountryInfoImpl({
    enName: "Bonaire | Sint Eustatius and Saba",
    mrName: "बोनरे | सिंट युस्टॅट्यूस आणि साबा",
    code: "5997"
  }),
  new CountryInfoImpl({enName: "Bosnia and Herzegovina", mrName: "बॉस्निया आणि हर्झगोव्हिना", code: "387"}),
  new CountryInfoImpl({enName: "Botswana", mrName: "बोट्सवाना", code: "267"}),
  new CountryInfoImpl({enName: "Brazil", mrName: "ब्राझील", code: "55"}),
  new CountryInfoImpl({enName: "British Indian Ocean Territory", mrName: "ब्रिटिश इंडियन ओशन टेरीटरी", code: "246"}),
  new CountryInfoImpl({enName: "Virgin Islands (British)", mrName: "व्हर्जिन बेटे (ब्रिटिश)", code: "1284"}),
  new CountryInfoImpl({enName: "Virgin Islands (U.S.)", mrName: "व्हर्जिन बेटे (US)", code: "340"}),
  new CountryInfoImpl({enName: "Brunei Darussalam", mrName: "ब्रुनै दारुसलाम", code: "673"}),
  new CountryInfoImpl({enName: "Bulgaria", mrName: "बल्गेरिया", code: "359"}),
  new CountryInfoImpl({enName: "Burkina Faso", mrName: "बुर्किना फासो", code: "226"}),
  new CountryInfoImpl({enName: "Burundi", mrName: "बुरुंडी", code: "257"}),
  new CountryInfoImpl({enName: "Cambodia", mrName: "कंबोडिया", code: "855"}),
  new CountryInfoImpl({enName: "Cameroon", mrName: "कॅमरुन", code: "237"}),
  new CountryInfoImpl({enName: "Canada", mrName: "कॅनडा", code: "1"}),
  new CountryInfoImpl({enName: "Cabo Verde", mrName: "काबो वर्दे", code: "238"}),
  new CountryInfoImpl({enName: "Cayman Islands", mrName: "केमन द्वीपसमूह", code: "1345"}),
  new CountryInfoImpl({enName: "Central African Republic", mrName: "सेंट्रल आफ्रिकन रिपब्लिक", code: "236"}),
  new CountryInfoImpl({enName: "Chad", mrName: "चाड", code: "235"}),
  new CountryInfoImpl({enName: "Chile", mrName: "चिली", code: "56"}),
  new CountryInfoImpl({enName: "China", mrName: "चीन", code: "86"}),
  new CountryInfoImpl({enName: "Christmas Island", mrName: "ख्रिसमस आयलॅन्ड", code: "61"}),
  new CountryInfoImpl({enName: "Cocos (Keeling) Islands", mrName: "कोकोस (केलिंग) आयलॅंन्ड", code: "61"}),
  new CountryInfoImpl({enName: "Colombia", mrName: "कोलंबिया", code: "57"}),
  new CountryInfoImpl({enName: "Comoros", mrName: "कोमोरोस", code: "269"}),
  new CountryInfoImpl({enName: "Congo", mrName: "कॉंगो", code: "242"}),
  new CountryInfoImpl({enName: "Democratic Republic of the Congo", mrName: "काँगोचे लोकशाही प्रजासत्ताक", code: "243"}),
  new CountryInfoImpl({enName: "Cook Islands", mrName: "कुक बेटे", code: "682"}),
  new CountryInfoImpl({enName: "Costa Rica", mrName: "कॉस्टा रिका", code: "506"}),
  new CountryInfoImpl({enName: "Croatia", mrName: "क्रोएशिया", code: "385"}),
  new CountryInfoImpl({enName: "Cuba", mrName: "क्युबा", code: "53"}),
  new CountryInfoImpl({enName: "Curaçao", mrName: "कुरॅको", code: "599"}),
  new CountryInfoImpl({enName: "Cyprus", mrName: "सायप्रस", code: "357"}),
  new CountryInfoImpl({enName: "Czech Republic", mrName: "झेक प्रजासत्ताक", code: "420"}),
  new CountryInfoImpl({enName: "Denmark", mrName: "डेन्मार्क", code: "45"}),
  new CountryInfoImpl({enName: "Djibouti", mrName: "जिबूती", code: "253"}),
  new CountryInfoImpl({enName: "Dominica", mrName: "डोमिनिका", code: "1767"}),
  new CountryInfoImpl({enName: "2Dominican Republic", mrName: "2Dominican प्रजासत्ताक", code: "1809"}),
  new CountryInfoImpl({enName: "Dominican Republic", mrName: "डोमिनिकन रिपब्लीक", code: "1829"}),
  new CountryInfoImpl({enName: "Dominican Republic", mrName: "डोमिनिकन रिपब्लीक", code: "1849"}),
  new CountryInfoImpl({enName: "Ecuador", mrName: "इक्वाडोर", code: "593"}),
  new CountryInfoImpl({enName: "Egypt", mrName: "इजिप्त", code: "20"}),
  new CountryInfoImpl({enName: "El Salvador", mrName: "अल साल्वाडोर", code: "503"}),
  new CountryInfoImpl({enName: "Equatorial Guinea", mrName: "इक्वेटोरियल गिनी", code: "240"}),
  new CountryInfoImpl({enName: "Eritrea", mrName: "इरिट्रिया", code: "291"}),
  new CountryInfoImpl({enName: "Estonia", mrName: "एस्टोनिया", code: "372"}),
  new CountryInfoImpl({enName: "Ethiopia", mrName: "इथिओपिया", code: "251"}),
  new CountryInfoImpl({enName: "Falkland Islands | Malvinas", mrName: "फॉकलंड बेटे | माल्व्हिनास", code: "500"}),
  new CountryInfoImpl({enName: "Faroe Islands", mrName: "फेरो द्वीपसमूह", code: "298"}),
  new CountryInfoImpl({enName: "Fiji", mrName: "फिजी", code: "679"}),
  new CountryInfoImpl({enName: "Finland", mrName: "फिनलंड", code: "358"}),
  new CountryInfoImpl({enName: "France", mrName: "फ्रान्स", code: "33"}),
  new CountryInfoImpl({enName: "French Guiana", mrName: "फ्रेंच गयाना", code: "594"}),
  new CountryInfoImpl({enName: "French Polynesia", mrName: "फ्रेंच पॉलिनेशिया", code: "689"}),
  new CountryInfoImpl({enName: "Gabon", mrName: "गॅबॉन", code: "241"}),
  new CountryInfoImpl({enName: "Gambia", mrName: "झांबिया", code: "220"}),
  new CountryInfoImpl({enName: "Georgia", mrName: "जॉर्जिया", code: "995"}),
  new CountryInfoImpl({enName: "Germany", mrName: "जर्मनी", code: "49"}),
  new CountryInfoImpl({enName: "Ghana", mrName: "घाना", code: "233"}),
  new CountryInfoImpl({enName: "Gibraltar", mrName: "जिब्राल्टर", code: "350"}),
  new CountryInfoImpl({enName: "Greece", mrName: "ग्रीस", code: "30"}),
  new CountryInfoImpl({enName: "Greenland", mrName: "ग्रीनलँड", code: "299"}),
  new CountryInfoImpl({enName: "Grenada", mrName: "ग्रेनाडा, विंडवर्ड आयलॅन्ड", code: "1473"}),
  new CountryInfoImpl({enName: "Guadeloupe", mrName: "ग्वादेलोप", code: "590"}),
  new CountryInfoImpl({enName: "Guam", mrName: "गुआम", code: "1671"}),
  new CountryInfoImpl({enName: "Guatemala", mrName: "ग्वाटेमाला", code: "502"}),
  new CountryInfoImpl({enName: "Guernsey", mrName: "खलाशाचे घट्ट विणीचे लोकरी कुडते", code: "44"}),
  new CountryInfoImpl({enName: "Guinea", mrName: "गिनी", code: "224"}),
  new CountryInfoImpl({enName: "Guinea-Bissau", mrName: "गिनी-बिसाउ", code: "245"}),
  new CountryInfoImpl({enName: "Guyana", mrName: "गयाना", code: "592"}),
  new CountryInfoImpl({enName: "Haiti", mrName: "हैती", code: "509"}),
  new CountryInfoImpl({enName: "Holy See", mrName: "होली", code: "379"}),
  new CountryInfoImpl({enName: "Honduras", mrName: "होंडुरास", code: "504"}),
  new CountryInfoImpl({enName: "Hong Kong", mrName: "हाँगकाँग", code: "852"}),
  new CountryInfoImpl({enName: "Hungary", mrName: "हंगेरी", code: "36"}),
  new CountryInfoImpl({enName: "Iceland", mrName: "आइसलँड", code: "354"}),
  new CountryInfoImpl({enName: "India", mrName: "भारत", code: "91"}),
  new CountryInfoImpl({enName: "Indonesia", mrName: "इंडोनेशिया", code: "62"}),
  new CountryInfoImpl({enName: "Côte d Ivoire", mrName: "आयव्हरी कोस्ट", code: "225"}),
  new CountryInfoImpl({enName: "Islamic Republic of Iran", mrName: "इराण इस्लामिक रिपब्लीक", code: "98"}),
  new CountryInfoImpl({enName: "Iraq", mrName: "इराक", code: "964"}),
  new CountryInfoImpl({enName: "Ireland", mrName: "आयर्लंड", code: "353"}),
  new CountryInfoImpl({enName: "Isle of Man", mrName: "आईल ऑफ मॅन", code: "44"}),
  new CountryInfoImpl({enName: "Israel", mrName: "इस्राएल", code: "972"}),
  new CountryInfoImpl({enName: "Italy", mrName: "इटली", code: "39"}),
  new CountryInfoImpl({enName: "Jamaica", mrName: "जमैका", code: "1876"}),
  new CountryInfoImpl({enName: "Japan", mrName: "जपान", code: "81"}),
  new CountryInfoImpl({enName: "Jersey", mrName: "जर्सी", code: "44"}),
  new CountryInfoImpl({enName: "Jordan", mrName: "जॉर्डन", code: "962"}),
  new CountryInfoImpl({enName: "Kazakhstan", mrName: "कझाकस्तान", code: "77"}),
  new CountryInfoImpl({enName: "Kazakhstan", mrName: "कझाकस्तान", code: "76"}),
  new CountryInfoImpl({enName: "Kenya", mrName: "केनिया", code: "254"}),
  new CountryInfoImpl({enName: "Kiribati", mrName: "किरिबाटी", code: "686"}),
  new CountryInfoImpl({enName: "Kuwait", mrName: "कुवैत", code: "965"}),
  new CountryInfoImpl({enName: "Kyrgyzstan", mrName: "किरगिझस्तान", code: "996"}),
  new CountryInfoImpl({
    enName: "Lao People's Democratic Republic",
    mrName: "लाओ पीपल्स डेमोक्रॅटिक रिपब्लिक",
    code: "856"
  }),
  new CountryInfoImpl({enName: "Latvia", mrName: "लाटविया", code: "371"}),
  new CountryInfoImpl({enName: "Lebanon", mrName: "लेबनॉन", code: "961"}),
  new CountryInfoImpl({enName: "Lesotho", mrName: "लेसोथो", code: "266"}),
  new CountryInfoImpl({enName: "Liberia", mrName: "लायबेरिया", code: "231"}),
  new CountryInfoImpl({enName: "Libya", mrName: "लिबिया", code: "218"}),
  new CountryInfoImpl({enName: "Liechtenstein", mrName: "लिंचेनस्टाइन", code: "423"}),
  new CountryInfoImpl({enName: "Lithuania", mrName: "लिथुआनिया", code: "370"}),
  new CountryInfoImpl({enName: "Luxembourg", mrName: "लक्झेंबर्ग", code: "352"}),
  new CountryInfoImpl({enName: "Macao", mrName: "मकाओ", code: "853"}),
  new CountryInfoImpl({
    enName: "Macedonia (Yugoslav Republic)",
    mrName: "मॅसेडोनिया (युगोस्लाव्ह प्रजासत्ताक)",
    code: "389"
  }),
  new CountryInfoImpl({enName: "Madagascar", mrName: "मादागास्कर", code: "261"}),
  new CountryInfoImpl({enName: "Malawi", mrName: "मलावी", code: "265"}),
  new CountryInfoImpl({enName: "Malaysia", mrName: "मलेशिया", code: "60"}),
  new CountryInfoImpl({enName: "Maldives", mrName: "मालदीव", code: "960"}),
  new CountryInfoImpl({enName: "Mali", mrName: "माली", code: "223"}),
  new CountryInfoImpl({enName: "Malta", mrName: "माल्टा", code: "356"}),
  new CountryInfoImpl({enName: "Marshall Islands", mrName: "मार्शल बेटे", code: "692"}),
  new CountryInfoImpl({enName: "Martinique", mrName: "मार्टिनिक", code: "596"}),
  new CountryInfoImpl({enName: "Mauritania", mrName: "मॉरिटानिया", code: "222"}),
  new CountryInfoImpl({enName: "Mauritius", mrName: "मॉरिशस", code: "230"}),
  new CountryInfoImpl({enName: "Mayotte", mrName: "मायोटी", code: "262"}),
  new CountryInfoImpl({enName: "Mexico", mrName: "मेक्सिको", code: "52"}),
  new CountryInfoImpl({enName: "Federated States of Micronesia", mrName: "मायक्रोनेशियाची संघीय राज्ये", code: "691"}),
  new CountryInfoImpl({enName: "Republic of Moldova", mrName: "मोल्दोव्हा प्रजासत्ताक", code: "373"}),
  new CountryInfoImpl({enName: "Monaco", mrName: "मोनॅको", code: "377"}),
  new CountryInfoImpl({enName: "Mongolia", mrName: "मंगोलिया", code: "976"}),
  new CountryInfoImpl({enName: "Montenegro", mrName: "माँटेनिग्रो", code: "382"}),
  new CountryInfoImpl({enName: "Montserrat", mrName: "मॉन्टसेरात", code: "1664"}),
  new CountryInfoImpl({enName: "Morocco", mrName: "मोरोक्को", code: "212"}),
  new CountryInfoImpl({enName: "Mozambique", mrName: "मोझांबिक", code: "258"}),
  new CountryInfoImpl({enName: "Myanmar", mrName: "म्यानमार", code: "95"}),
  new CountryInfoImpl({enName: "Namibia", mrName: "नामिबिया", code: "264"}),
  new CountryInfoImpl({enName: "Nauru", mrName: "नारू", code: "674"}),
  new CountryInfoImpl({enName: "Nepal", mrName: "नेपाळ", code: "977"}),
  new CountryInfoImpl({enName: "Netherlands", mrName: "नेदरलँड्स", code: "31"}),
  new CountryInfoImpl({enName: "New Caledonia", mrName: "न्यू कॅलेडोनिया", code: "687"}),
  new CountryInfoImpl({enName: "New Zealand", mrName: "न्युझीलँड", code: "64"}),
  new CountryInfoImpl({enName: "Nicaragua", mrName: "निकाराग्वा", code: "505"}),
  new CountryInfoImpl({enName: "Niger", mrName: "नायजर", code: "227"}),
  new CountryInfoImpl({enName: "Nigeria", mrName: "नायजेरिया", code: "234"}),
  new CountryInfoImpl({enName: "Niue", mrName: "नीयू", code: "683"}),
  new CountryInfoImpl({enName: "Norfolk Island", mrName: "नॉरफोक द्वीप", code: "672"}),
  new CountryInfoImpl({
    enName: "Democratic People's Republic of Korea",
    mrName: "कोरिया लोकशाही प्रजासत्ताक",
    code: "850"
  }),
  new CountryInfoImpl({enName: "Northern Mariana Islands", mrName: "उत्तरी मारियाना बेटे", code: "1670"}),
  new CountryInfoImpl({enName: "Norway", mrName: "नॉर्वे", code: "47"}),
  new CountryInfoImpl({enName: "Oman", mrName: "ओमान", code: "968"}),
  new CountryInfoImpl({enName: "Pakistan", mrName: "पाकिस्तान", code: "92"}),
  new CountryInfoImpl({enName: "Palau", mrName: "पलाऊ", code: "680"}),
  new CountryInfoImpl({enName: "State of Palestine", mrName: "पॅलेस्टाईन राज्य", code: "970"}),
  new CountryInfoImpl({enName: "Panama", mrName: "पनामा", code: "507"}),
  new CountryInfoImpl({enName: "Papua New Guinea", mrName: "पापुआ न्यू गिनी", code: "675"}),
  new CountryInfoImpl({enName: "Paraguay", mrName: "पराग्वे", code: "595"}),
  new CountryInfoImpl({enName: "Peru", mrName: "पेरू", code: "51"}),
  new CountryInfoImpl({enName: "Philippines", mrName: "फिलीपिन्स", code: "63"}),
  new CountryInfoImpl({enName: "Pitcairn", mrName: "पिटकेर्न", code: "64"}),
  new CountryInfoImpl({enName: "Poland", mrName: "पोलंड", code: "48"}),
  new CountryInfoImpl({enName: "Portugal", mrName: "पोर्तुगाल", code: "351"}),
  new CountryInfoImpl({enName: "Puerto Rico", mrName: "पोर्तु रिको", code: "1787"}),
  new CountryInfoImpl({enName: "Puerto Rico", mrName: "पोर्तु रिको", code: "1939"}),
  new CountryInfoImpl({enName: "Qatar", mrName: "कतार", code: "974"}),
  new CountryInfoImpl({enName: "Republic of Kosovo", mrName: "कोसोव्हो प्रजासत्ताक", code: "383"}),
  new CountryInfoImpl({enName: "Réunion", mrName: "रियूनियन", code: "262"}),
  new CountryInfoImpl({enName: "Romania", mrName: "रोमानिया", code: "40"}),
  new CountryInfoImpl({enName: "Russian Federation", mrName: "रशियाचे संघराज्य", code: "7"}),
  new CountryInfoImpl({enName: "Rwanda", mrName: "रवांडा", code: "250"}),
  new CountryInfoImpl({enName: "Saint Barthélemy", mrName: "सेंट बार्थेलेमी", code: "590"}),
  new CountryInfoImpl({
    enName: "Saint Helena | Ascension and Tristan da Cunha",
    mrName: "सेंट हेलेना | असेन्शन आणि ट्रिस्टन दा कुन्हा",
    code: "290"
  }),
  new CountryInfoImpl({enName: "Saint Kitts and Nevis", mrName: "सेंट किट्स आणि नेव्हिस", code: "1869"}),
  new CountryInfoImpl({enName: "Saint Lucia", mrName: "सेंट लुसिया", code: "1758"}),
  new CountryInfoImpl({enName: "Saint Martin (French part)", mrName: "सेंट मार्टिन (फ्रेंच भाग)", code: "590"}),
  new CountryInfoImpl({enName: "Saint Pierre and Miquelon", mrName: "सेंट पियेर व मिकेलो", code: "508"}),
  new CountryInfoImpl({
    enName: "Saint Vincent and the Grenadines",
    mrName: "सेंट व्हिन्सेंट आणि ग्रेनेडीन्स",
    code: "1784"
  }),
  new CountryInfoImpl({enName: "Samoa", mrName: "सामोआ", code: "685"}),
  new CountryInfoImpl({enName: "San Marino", mrName: "सॅन मरिनो", code: "378"}),
  new CountryInfoImpl({enName: "Sao Tome and Principe", mrName: "साओ टोमे व प्रिन्सिप", code: "239"}),
  new CountryInfoImpl({enName: "Saudi Arabia", mrName: "सौदी अरेबिया", code: "966"}),
  new CountryInfoImpl({enName: "Senegal", mrName: "सेनेगल", code: "221"}),
  new CountryInfoImpl({enName: "Serbia", mrName: "सर्बिया", code: "381"}),
  new CountryInfoImpl({enName: "Seychelles", mrName: "सेशेल्स", code: "248"}),
  new CountryInfoImpl({enName: "Sierra Leone", mrName: "सिएरा लिऑन", code: "232"}),
  new CountryInfoImpl({enName: "Singapore", mrName: "सिंगापूर", code: "65"}),
  new CountryInfoImpl({enName: "Sint Maarten (Dutch part)", mrName: "सिंट मार्टेन (डच भाग)", code: "1721"}),
  new CountryInfoImpl({enName: "Slovakia", mrName: "स्लोव्हाकिया", code: "421"}),
  new CountryInfoImpl({enName: "Slovenia", mrName: "स्लोव्हेनिया", code: "386"}),
  new CountryInfoImpl({enName: "Solomon Islands", mrName: "सोलोमन आयलॅन्ड", code: "677"}),
  new CountryInfoImpl({enName: "Somalia", mrName: "सोमालिया", code: "252"}),
  new CountryInfoImpl({enName: "South Africa", mrName: "दक्षिण आफ्रिका", code: "27"}),
  new CountryInfoImpl({
    enName: "South Georgia and the South Sandwich Islands",
    mrName: "दक्षिण जॉर्जिया आणि दक्षिण सँडविच बेटे",
    code: "500"
  }),
  new CountryInfoImpl({enName: "Korea (Republic of)", mrName: "कोरिया (गणराज्य)", code: "82"}),
  new CountryInfoImpl({enName: "South Sudan", mrName: "दक्षिण सुदान", code: "211"}),
  new CountryInfoImpl({enName: "Spain", mrName: "स्पेन", code: "34"}),
  new CountryInfoImpl({enName: "Sri Lanka", mrName: "श्रीलंका", code: "94"}),
  new CountryInfoImpl({enName: "Sudan", mrName: "सुदान", code: "249"}),
  new CountryInfoImpl({enName: "Suriname", mrName: "सुरिनाम", code: "597"}),
  new CountryInfoImpl({enName: "Svalbard and Jan Mayen", mrName: "स्वालबार्ड आणि यान मायेन", code: "4779"}),
  new CountryInfoImpl({enName: "Swaziland", mrName: "स्वाझीलँड", code: "268"}),
  new CountryInfoImpl({enName: "Sweden", mrName: "स्वीडन", code: "46"}),
  new CountryInfoImpl({enName: "Switzerland", mrName: "स्वित्झर्लंड", code: "41"}),
  new CountryInfoImpl({enName: "Syrian Arab Republic", mrName: "सिरियन अरब रिपब्लीक", code: "963"}),
  new CountryInfoImpl({enName: "Taiwan", mrName: "तैवान", code: "886"}),
  new CountryInfoImpl({enName: "Tajikistan", mrName: "ताजिकिस्तान", code: "992"}),
  new CountryInfoImpl({enName: "United Republic of Tanzania", mrName: "टांझानिया युनायटेड रिपब्लिक ऑफ", code: "255"}),
  new CountryInfoImpl({enName: "Thailand", mrName: "थायलंड", code: "66"}),
  new CountryInfoImpl({enName: "Timor-Leste", mrName: "पूर्व तिमोर", code: "670"}),
  new CountryInfoImpl({enName: "Togo", mrName: "जाण्यासाठी", code: "228"}),
  new CountryInfoImpl({enName: "Tokelau", mrName: "टोकेलाऊ", code: "690"}),
  new CountryInfoImpl({enName: "Tonga", mrName: "टोंगा", code: "676"}),
  new CountryInfoImpl({enName: "Trinidad and Tobago", mrName: "त्रिनिदाद आणि टोबॅगो", code: "1868"}),
  new CountryInfoImpl({enName: "Tunisia", mrName: "ट्युनिशिया", code: "216"}),
  new CountryInfoImpl({enName: "Turkey", mrName: "तुर्की", code: "90"}),
  new CountryInfoImpl({enName: "Turkmenistan", mrName: "तुर्कमेनिस्तान", code: "993"}),
  new CountryInfoImpl({enName: "Turks and Caicos Islands", mrName: "टर्क्स आणि कैकास द्वीपसमूह", code: "1649"}),
  new CountryInfoImpl({enName: "Tuvalu", mrName: "टुवालु", code: "688"}),
  new CountryInfoImpl({enName: "Uganda", mrName: "युगांडा", code: "256"}),
  new CountryInfoImpl({enName: "Ukraine", mrName: "युक्रेन", code: "380"}),
  new CountryInfoImpl({enName: "United Arab Emirates", mrName: "संयुक्त अरब अमिराती", code: "971"}),
  new CountryInfoImpl({
    enName: "United Kingdom of Great Britain and Northern Ireland",
    mrName: "ग्रेट ब्रिटन व उत्तर आयर्लंड",
    code: "44"
  }),
  new CountryInfoImpl({enName: "United States of America", mrName: "युनायटेड स्टेट्स ऑफ अमेरिका", code: "1"}),
  new CountryInfoImpl({enName: "Uruguay", mrName: "उरुग्वे", code: "598"}),
  new CountryInfoImpl({enName: "Uzbekistan", mrName: "उझबेकिस्तान", code: "998"}),
  new CountryInfoImpl({enName: "Vanuatu", mrName: "वानौटु", code: "678"}),
  new CountryInfoImpl({
    enName: "Bolivarian Republic of Venezuela",
    mrName: "व्हेनेझुएला व्हर्जिन बेटे, यू.एस.",
    code: "58"
  }),
  new CountryInfoImpl({enName: "Viet Nam", mrName: "व्हिएतनाम", code: "84"}),
  new CountryInfoImpl({enName: "Wallis and Futuna", mrName: "वॉलीस आणि फुतुना", code: "681"}),
  new CountryInfoImpl({enName: "Western Sahara", mrName: "पश्चिम सहारा", code: "212"}),
  new CountryInfoImpl({enName: "Yemen", mrName: "येमेन", code: "967"}),
  new CountryInfoImpl({enName: "Zambia", mrName: "झांबिया", code: "260"}),
  new CountryInfoImpl({enName: "Zimbabwe", mrName: "झिंबाब्वे", code: "263"})
];

export const isoCodeMap = new Map<number, string>([
  [93, "AF"],
  [358, "AX"],
  [355, "AL"],
  [213, "DZ"],
  [1684, "AS"],
  [376, "AD"],
  [244, "AO"],
  [1264, "AI"],
  [672, "AQ"],
  [1268, "AG"],
  [54, "AR"],
  [374, "AM"],
  [297, "AW"],
  [61, "AU"],
  [43, "AT"],
  [994, "AZ"],
  [1242, "BS"],
  [973, "BH"],
  [880, "BD"],
  [1246, "BB"],
  [375, "BY"],
  [32, "BE"],
  [501, "BZ"],
  [229, "BJ"],
  [1441, "BM"],
  [975, "BT"],
  [591, "BO"],
  [5997, "BQ"],
  [387, "BA"],
  [267, "BW"],
  [55, "BR"],
  [246, "IO"],
  [1284, "VG"],
  [1340, "VI"],
  [673, "BN"],
  [359, "BG"],
  [226, "BF"],
  [257, "BI"],
  [855, "KH"],
  [237, "CM"],
  [1, "CA"],
  [238, "CV"],
  [1345, "KY"],
  [236, "CF"],
  [235, "TD"],
  [56, "CL"],
  [86, "CN"],
  [61, "CX"],
  [61, "CC"],
  [57, "CO"],
  [269, "KM"],
  [242, "CG"],
  [243, "CD"],
  [682, "CK"],
  [506, "CR"],
  [385, "HR"],
  [53, "CU"],
  [599, "CW"],
  [357, "CY"],
  [420, "CZ"],
  [45, "DK"],
  [253, "DJ"],
  [1767, "DM"],
  [1809, "DO"],
  [1829, "DO"],
  [1849, "DO"],
  [593, "EC"],
  [20, "EG"],
  [503, "SV"],
  [240, "GQ"],
  [291, "ER"],
  [372, "EE"],
  [251, "ET"],
  [500, "FK"],
  [298, "FO"],
  [679, "FJ"],
  [358, "FI"],
  [33, "FR"],
  [594, "GF"],
  [689, "PF"],
  [241, "GA"],
  [220, "GM"],
  [995, "GE"],
  [49, "DE"],
  [233, "GH"],
  [350, "GI"],
  [30, "GR"],
  [299, "GL"],
  [1473, "GD"],
  [590, "GP"],
  [1671, "GU"],
  [502, "GT"],
  [44, "GG"],
  [224, "GN"],
  [245, "GW"],
  [592, "GY"],
  [509, "HT"],
  [379, "VA"],
  [504, "HN"],
  [852, "HK"],
  [36, "HU"],
  [354, "IS"],
  [91, "IN"],
  [62, "ID"],
  [225, "CI"],
  [98, "IR"],
  [964, "IQ"],
  [353, "IE"],
  [44, "IM"],
  [972, "IL"],
  [39, "IT"],
  [1876, "JM"],
  [81, "JP"],
  [44, "JE"],
  [962, "JO"],
  [76, "KZ"],
  [77, "KZ"],
  [254, "KE"],
  [686, "KI"],
  [965, "KW"],
  [996, "KG"],
  [856, "LA"],
  [371, "LV"],
  [961, "LB"],
  [266, "LS"],
  [231, "LR"],
  [218, "LY"],
  [423, "LI"],
  [370, "LT"],
  [352, "LU"],
  [853, "MO"],
  [389, "MK"],
  [261, "MG"],
  [265, "MW"],
  [60, "MY"],
  [960, "MV"],
  [223, "ML"],
  [356, "MT"],
  [692, "MH"],
  [596, "MQ"],
  [222, "MR"],
  [230, "MU"],
  [262, "YT"],
  [52, "MX"],
  [691, "FM"],
  [373, "MD"],
  [377, "MC"],
  [976, "MN"],
  [382, "ME"],
  [1664, "MS"],
  [212, "MA"],
  [258, "MZ"],
  [95, "MM"],
  [264, "NA"],
  [674, "NR"],
  [977, "NP"],
  [31, "NL"],
  [687, "NC"],
  [64, "NZ"],
  [505, "NI"],
  [227, "NE"],
  [234, "NG"],
  [683, "NU"],
  [672, "NF"],
  [850, "KP"],
  [1670, "MP"],
  [47, "NO"],
  [968, "OM"],
  [92, "PK"],
  [680, "PW"],
  [970, "PS"],
  [507, "PA"],
  [675, "PG"],
  [595, "PY"],
  [51, "PE"],
  [63, "PH"],
  [64, "PN"],
  [48, "PL"],
  [351, "PT"],
  [1787, "PR"],
  [1939, "PR"],
  [974, "QA"],
  [383, "XK"],
  [262, "RE"],
  [40, "RO"],
  [7, "RU"],
  [250, "RW"],
  [590, "BL"],
  [290, "SH"],
  [1869, "KN"],
  [1758, "LC"],
  [590, "MF"],
  [508, "PM"],
  [1784, "VC"],
  [685, "WS"],
  [378, "SM"],
  [239, "ST"],
  [966, "SA"],
  [221, "SN"],
  [381, "RS"],
  [248, "SC"],
  [232, "SL"],
  [65, "SG"],
  [1721, "SX"],
  [421, "SK"],
  [386, "SI"],
  [677, "SB"],
  [252, "SO"],
  [27, "ZA"],
  [500, "GS"],
  [82, "KR"],
  [211, "SS"],
  [34, "ES"],
  [94, "LK"],
  [249, "SD"],
  [597, "SR"],
  [4779, "SJ"],
  [268, "SZ"],
  [46, "SE"],
  [41, "CH"],
  [963, "SY"],
  [886, "TW"],
  [992, "TJ"],
  [255, "TZ"],
  [66, "TH"],
  [670, "TL"],
  [228, "TG"],
  [690, "TK"],
  [676, "TO"],
  [1868, "TT"],
  [216, "TN"],
  [90, "TR"],
  [993, "TM"],
  [1649, "TC"],
  [688, "TV"],
  [256, "UG"],
  [380, "UA"],
  [971, "AE"],
  [44, "GB"],
  [1, "US"],
  [598, "UY"],
  [998, "UZ"],
  [678, "VU"],
  [58, "VE"],
  [84, "VN"],
  [681, "WF"],
  [212, "EH"],
  [967, "YE"],
  [260, "ZM"],
  [263, "ZW"]
]);
