<nav class="container">
  <div fxFlex="row" fxFill fxLayoutAlign="space-between center">
    <div class="logo">देशमुखी</div>
    <button mat-stroked-button
            [matMenuTriggerFor]="languageMenu">
      <mat-icon>g_translate</mat-icon>
      <span fxHide.lt-sm>{{strings.language}}</span>
      <mat-menu #languageMenu="matMenu" overlapTrigger>
        <button mat-menu-item (click)="onEnglishSelected()">{{strings.languageEn}}</button>
        <button mat-menu-item (click)="onMarathiSelected()">{{strings.languageMr}}</button>
      </mat-menu>
    </button>
  </div>
</nav>
