import {AbstractControl, ValidatorFn} from '@angular/forms';
import * as phoneNumberLib from 'google-libphonenumber';
import {isoCodeMap} from "../../../data/countries";
import {assembleI18nBoundString} from "@angular/compiler/src/render3/view/i18n/util";

export class OriginalSurnameValidator {

  static validOriginalSurname(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      console.log(`S Value is ${control.value}`)
      const forbidden = control.value == "deshmukh" || control.value == "देशमुख";
      console.log(`S Forbidden: ${forbidden}`)
      return forbidden ? {isValidSurname: {value: control.value}} : null;
    };
  }
}
